.edit-template-modal {
  .modal-content {
    display: flex;
    height: 80vh;

    .ant-form-item-control-input-content {
      height: 78vh;
    }

    .editor-container {
      width: 50%;
    }

    .preview-container {
      width: 50%;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

.preview-template-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    height: 80vh;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

.edit-template-wrapper {
  .page-wrapper-body {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    .add-edit-form {
      width: 50%;
    }

    .preview-container {
      width: 50%;
      height: 80vh;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
}

.custom-external-input {
  .ant-input {
    max-height: 40px;
  }

  .ant-input-affix-wrapper {
    font-size: 14px;
    max-height: 40px;
    display: flex;
    align-items: center;

    .ant-input-suffix {
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
}
